
<div class="form-container">
    <button class="back-button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>Update WorkType</h2>
    <form [formGroup]="workForm">
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-12 col-lg-4">
          <label for="workType">Work Type<span class="requried-field">*</span></label>
          <input
            type="text"
            id="workType"
            placeholder="Enter workType"
            class="form-control"
            formControlName="workType"
            [ngClass]="{ 'is-invalid': submitted && f['workType'].errors }"
          />
          <div *ngIf="submitted && f['workType'].errors" class="invalid-feedback">
            <div *ngIf="f['workType'].errors['required']">Work Type is required</div>
          </div>
        </div>
        <div class="form-group col-sm-12 col-md-12 col-lg-4">
            <label for="unitType">Unit Type<span class="requried-field">*</span></label>
            <input
              type="text"
              id="unitType"
              placeholder="Enter unitType"
              class="form-control"
              formControlName="unitType"
              [ngClass]="{ 'is-invalid': submitted && f['unitType'].errors }"
            />
            <div *ngIf="submitted && f['unitType'].errors" class="invalid-feedback">
              <div *ngIf="f['unitType'].errors['required']">Unit Type is required</div>
            </div>
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-4">
            <label for="unitAmount">Unit Amount<span class="requried-field">*</span></label>
            <input
              type="text"
              id="unitAmount"
              placeholder="Enter unitAmount"
              class="form-control"
              formControlName="unitAmount"
              [ngClass]="{ 'is-invalid': submitted && f['unitAmount'].errors }"
            />
            <div *ngIf="submitted && f['unitAmount'].errors" class="invalid-feedback">
              <div *ngIf="f['unitAmount'].errors['required']">Unit Amount is required</div>
            </div>
          </div>
        </div>
       
      <div class="formbutton">
        <button type="submit" class="btn btn-primary" (click)="onSubmit(workForm.value)">Submit</button>
        <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
      </div>
    </form>
  </div>
  